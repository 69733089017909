import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import amplitude from "amplitude-js";

const PAGE = "Social";

const Social = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page");
  }, []);

  const handleLinkClick = (link) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + link);
  };

  return (
    <>
      <div className="section">
        <div className="div-block">
          <h1 className="heading">social</h1>
          <p className="paragraph">social links</p>
          <div className="div-block-2">
            <a
              href="https://www.facebook.com/joshchoi1999/"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Facebook")}
                src="logos/facebook.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://www.instagram.com/josh.choi99/"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Instagram")}
                src="logos/instagram.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://twitter.com/jclvsh"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Twitter")}
                src="logos/twitter.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="logos/snapcode.svg"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Snapchat")}
                src="logos/snapchat.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <Link to="/caughtyouin8k">
              <img
                onClick={() => handleLinkClick("OnlyFans")}
                src="logos/onlyfans.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Social;
