import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Mogle from "./components/Mogle";
import Freelance from "./components/Freelance";
import Music from "./components/Music";
import Social from "./components/Social";
import CaughtYouIn8K from "./components/CaughtYouIn8K";
import NotFound from "./components/NotFound";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/mogle" exact component={Mogle} />
          <Route path="/freelance" exact component={Freelance} />
          <Route path="/music" exact component={Music} />
          <Route path="/social" exact component={Social} />
          <Route path="/caughtyouin8k" exact component={CaughtYouIn8K} />
          <Route path="/caughtyouin8k" exact component={CaughtYouIn8K} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
