import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";

const PAGE = "Freelance";
const DEFAULT_CATEGORY = {
  all: true,
  apps: false,
  ai: false,
  defi: false,
  nfts: false,
  daos: false,
  gaming: false,
  ethereum: false,
  polygon: false,
  bsc: false,
  solana: false,
  terra: false,
  flow: false,
  ecommerce: false
};

const Freelance = () => {
  const [category, setCategory] = useState(DEFAULT_CATEGORY);

  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page");
  }, []);

  const handleLinkClick = (link) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + link);
  };

  const handleButtonClick = (button) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + button);
  };

  function handleCardClick(card) {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + card);
  }

  const handleCategoryClick = (category) => {
    if (category === "all") {
      setCategory({
        all: true,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "apps") {
      setCategory({
        all: false,
        apps: true,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "ai") {
      setCategory({
        all: false,
        apps: false,
        ai: true,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "defi") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: true,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "nfts") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: true,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "daos") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: true,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "gaming") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: true,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "ethereum") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: true,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "polygon") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: true,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "bsc") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: true,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "solana") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: true,
        terra: false,
        flow: false,
        ecommerce: false
      });
    } else if (category === "terra") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: true,
        flow: false,
        ecommerce: false
      });
    } else if (category === "flow") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: true,
        ecommerce: false
      });
    } else if (category === "ecommerce") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false,
        ecommerce: true
      });
    }
  };

  return (
    <>
      <div className="section">
        <div className="div-block">
          <h1 className="heading">freelance</h1>
          <p className="paragraph">music, tutoring, full-stack programming</p>
          <div className="div-block-2">
            <a
              href="mailto:josh@jclvsh.art"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/gmail.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("GMail")}
              />
            </a>
            <a
              href="https://www.toptal.com/resume/josh-choi"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/toptal.png"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Toptal")}
              />
            </a>
            <a
              href="https://app.usebraintrust.com/talent/50343/"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/braintrust.png"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Braintrust")}
              />
            </a>
            <a
              href="https://www.fiverr.com/joshchoi4881"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/fiverr.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Fiverr")}
              />
            </a>
            <a
              href="https://www.upwork.com/freelancers/~011b4aa7ac78aae607"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/upwork.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Upwork")}
              />
            </a>
            <a
              href="https://www.freelancer.com/u/joshchoi4881"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/freelancer.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Freelancer")}
              />
            </a>
          </div>
        </div>
        <div className="div-block-3">
          <h1 className="heading">portfolio</h1>
          <div className="buttons">
            <button
              id={category["all"] ? "all-clicked" : "all-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("all");
                handleButtonClick("all");
              }}
            >
              all
            </button>
            <button
              id={category["apps"] ? "apps-clicked" : "apps-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("apps");
                handleButtonClick("apps");
              }}
            >
              apps
            </button>
            <button
              id={category["ai"] ? "ai-clicked" : "ai-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("ai");
                handleButtonClick("ai");
              }}
            >
              ai
            </button>
            <button
              id={category["defi"] ? "defi-clicked" : "defi-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("defi");
                handleButtonClick("defi");
              }}
            >
              defi
            </button>
            <button
              id={category["nfts"] ? "nfts-clicked" : "nfts-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("nfts");
                handleButtonClick("nfts");
              }}
            >
              NFTs
            </button>
            <button
              id={category["daos"] ? "daos-clicked" : "daos-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("daos");
                handleButtonClick("daos");
              }}
            >
              DAOs
            </button>
            <button
              id={category["gaming"] ? "gaming-clicked" : "gaming-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("gaming");
                handleButtonClick("gaming");
              }}
            >
              gaming
            </button>
            <button
              id={
                category["ethereum"] ? "ethereum-clicked" : "ethereum-unclicked"
              }
              className="btn button"
              onClick={() => {
                handleCategoryClick("ethereum");
                handleButtonClick("ethereum");
              }}
            >
              ethereum
            </button>
            <button
              id={category["polygon"] ? "polygon-clicked" : "polygon-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("polygon");
                handleButtonClick("polygon");
              }}
            >
              polygon
            </button>
            <button
              id={category["bsc"] ? "bsc-clicked" : "bsc-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("bsc");
                handleButtonClick("bsc");
              }}
            >
              binance smart chain
            </button>
            <button
              id={category["solana"] ? "solana-clicked" : "solana-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("solana");
                handleButtonClick("solana");
              }}
            >
              solana
            </button>
            <button
              id={category["terra"] ? "terra-clicked" : "terra-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("terra");
                handleButtonClick("terra");
              }}
            >
              terra
            </button>
            <button
              id={category["flow"] ? "flow-clicked" : "flow-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("flow");
                handleButtonClick("flow");
              }}
            >
              flow
            </button>
            <button
              id={category["ecommerce"] ? "ecommerce-clicked" : "ecommerce-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("ecommerce");
                handleButtonClick("ecommerce");
              }}
            >
              ecommerce
            </button>
          </div>
          <div className="cards wrap">

            {/* Vandra */}
            <a
              href="https://www.vandra.ai/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["ai"] ||
                  category["ecommerce"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Vandra")}
            >
              <img
                src="logos/vandra-logo.png"
                alt="vandra logo"
              />
              <p className="paragraph">intelligent discounting using ai</p>
              <p className="paragraph">
                tech stack: html, css, javscript, react, python, flask, shopify api, h20 api, sqlalchemy, postgresql
              </p>
              <p className="paragraph">
                features: extensions, dashboard, analytics, ecommerce, ai
              </p>
              <img src="images/vandra-homepage.png" alt="vandra homepage" />
            </a>

            {/* Mogle */}
            <a
              href="https://mogleapp.com/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["defi"] ||
                  category["nfts"] ||
                  category["daos"] ||
                  category["ethereum"] ||
                  category["polygon"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Mogle")}
            >
              <img
                id="mogle-logo"
                src="logos/mogle-logo.png"
                alt="mogle logo"
              />
              <p className="paragraph">product suite for freelancers in web3</p>
              <p className="paragraph">
                tech stack: html, css, javascript, react, redux, node.js,
                express, python, flask, numpy, scipy, nosql, dynamodb, moralis,
                amazon web services, ethereum, polygon
              </p>
              <p className="paragraph">
                features: wallet authentication, dashboard, analytics,
                marketplace, smart contracts, defi, NFTs, DAOs, ethereum
                blockchain, polygon blockchain, web3
              </p>
              <img src="images/mogle-homepage.png" alt="mogle homepage" />
            </a>

            {/* Reitium */}
            <a
              href="https://reitium.fund/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["apps"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Reitium")}
            >
              <img src="logos/reitium-logo.png" alt="reitium logo" />
              <p className="paragraph">
                reitium is a technology platform that makes investing simple and
                accessible to all by providing a safe, secure, reliable, trading
                platform for private investments
              </p>
              <p className="paragraph">
                tech stack: html, css, tailwind, javascript, typescript, react,
                next.js, redux
              </p>
              <p className="paragraph">
                features: email authentication, dashboard, marketplace,
                portfolio
              </p>
              <img src="images/reitium-homepage.png" alt="reitium homepage" />
            </a>

            {/* Fantera */}
            <a
              href="https://fantera-webapp-v1.vercel.app/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["nfts"] ||
                  category["daos"] ||
                  category["ethereum"] ||
                  category["polygon"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Fantera")}
            >
              <img src="logos/fantera-logo.png" alt="fantera logo" />
              <p className="paragraph">football belongs to the fans</p>
              <p className="paragraph">
                tech stack: html, css, tailwind, javascript, react, next.js,
                redux, firebase, ethereum, polygon
              </p>
              <p className="paragraph">
                features: email, social, and wallet authentication; dashboard;
                challenges; votes; media; smart contracts; NFTs; DAOs; ethereum
                blockchain; polygon blockchain; web3
              </p>
              <img src="images/fantera-homepage.png" alt="fantera homepage" />
            </a>

            {/* Mobo */}
            <a
              href="https://mobo.xyz/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["nfts"] ||
                  category["ethereum"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Mobo")}
            >
              <img src="logos/mobo-logo.png" alt="mobo logo" />
              <p className="paragraph">
                the best link-in-bio tool for NFT creators
              </p>
              <p className="paragraph">
                tech stack: html, css, javascript, react, redux, node.js,
                express, twitter api, nftport api, opensea api, rarible api,
                nosql, mongodb, moralis, amazon web services
              </p>
              <p className="paragraph">
                features: twitter and wallet authentication, dashboard,
                profiles, analytics, NFTs, ethereum blockchain, web3
              </p>
              <img src="images/mobo-homepage.png" alt="mobo homepage" />
            </a>

            {/* Thirdwave */}
            <a
              href="https://www.thirdwavelabs.com/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["defi"] ||
                  category["nfts"] ||
                  category["daos"] ||
                  category["gaming"] ||
                  category["ethereum"] ||
                  category["polygon"] ||
                  category["bsc"] ||
                  category["flow"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Thirdwave")}
            >
              <img
                id="thirdwave-logo"
                src="logos/thirdwave-logo.svg"
                alt="thirdwave logo"
              />
              <p className="paragraph">
                thirdwave provides blockchain data insights for gaming dapps
              </p>
              <p className="paragraph">
                tech stack: python, sql, postgresql, amazon web services,
                ethereum, polygon, binance smart chain, flow
              </p>
              <p className="paragraph">
                features: blockchain data insights for gaming dapps, ethereum
                blockchain, polygon blockchain, binance smart chain blockchain,
                flow blockchain, web3
              </p>
              <img
                src="images/thirdwave-homepage.png"
                alt="thirdwave homepage"
              />
            </a>

            {/* Metaphy Labs */}
            <a
              href="https://metaphy-labs.xyz/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["ethereum"] ||
                  category["polygon"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Metaphy")}
            >
              <img
                id="metaphy-logo"
                src="logos/metaphy-logo.jpeg"
                alt="metaphy logo"
              />
              <p className="paragraph">
                metaphy labs is a web3 product studio designed to help founders
                plan and develop their ideas into profitable web3 products
              </p>
              <p className="paragraph">
                tech stack: node.js, express, prisma, sql, postgresql, docker,
                amazon web services, aws s3, aws api gateway, aws lambda, aws
                cloudwatch, aws sqs, aws eventbridge
              </p>
              <p className="paragraph">
                features: backend and devops for finflux and hivetool clients
              </p>
              <img src="images/metaphy-homepage.png" alt="metaphy homepage" />
            </a>

            {/* CentServ */}
            <a
              href="https://centserv.herokuapp.com/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["apps"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("CentServ")}
            >
              <h1 className="heading">centserv</h1>
              <p className="paragraph">
                full-stack service center management platform for 3nstar
              </p>
              <p className="paragraph">
                tech stack: html, css, tailwind, javascript, react, redux,
                node.js, express, prisma, postgresql, heroku
              </p>
              <p className="paragraph">
                features: email authentication; admin and service center views;
                inventories; replenish, adjustment, and service tickets; admin
                crud; csv file upload; export to csv file
              </p>
              <img src="images/centserv-homepage.png" alt="centserv homepage" />
            </a>

            {/* Slant */}
            <a
              href="http://www.slantapp.com/slant/homepage.php"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["apps"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Slant")}
            >
              <img
                id="slant-logo"
                src="logos/slant-logo.jpg"
                alt="slant logo"
              />
              <p className="paragraph">
                media platform to gauge public opinion on topics ranging from
                politics to film
              </p>
              <p className="paragraph">
                tech stack: html, css, javascript, jquery, vue.js, php, sql,
                mysql, amazon web services
              </p>
              <p className="paragraph">
                features: email authentication, profiles, search, posts,
                comments, likes, polls, notifications, inbox, settings
              </p>
              <img src="images/slant-homepage.png" alt="slant homepage" />
            </a>

            {/* freestyler */}
            <a
              href="https://bit.ly/jclvsh-freestyler"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["ai"] ? "card card-link" : "hidden"
              }
              onClick={() => handleCardClick("freestyler")}
            >
              <h1 className="heading">freestyler</h1>
              <p className="paragraph">
                generate a freestyle rap from your favorite rapper using ai
              </p>
              <p className="paragraph">
                tech stack: html, css, javascript, react, next.js, chatgpt
              </p>
              <p className="paragraph">features: ai</p>
              <img
                src="images/freestyler-homepage.png"
                alt="freestyler homepage"
              />
            </a>

            {/* jcgen */}
            <a
              href="https://bit.ly/jclvsh-jcgen"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["ai"] ? "card card-link" : "hidden"
              }
              onClick={() => handleCardClick("jcgen")}
            >
              <h1 className="heading">jcgen</h1>
              <p className="paragraph">generate images of me using ai</p>
              <p className="paragraph">
                tech stack: html, css, javascript, react, next.js, jupyter
                notebook, google colab, stable diffusion
              </p>
              <p className="paragraph">features: ai</p>
              <img src="images/jcgen-homepage.png" alt="jcgen homepage" />
            </a>

            {/* hip */}
            <a
              href="https://bit.ly/jclvsh-hip"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["ethereum"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("hip")}
            >
              <h1 className="heading">hip</h1>
              <p className="paragraph">get hip to new artists and music</p>
              <p className="paragraph">
                tech stack: react, hardhat, metamask, solidity, ethereum
              </p>
              <p className="paragraph">
                features: wallet authentication, smart contracts, ethereum
                blockchain, web3
              </p>
              <img src="images/hip-homepage.png" alt="hip homepage" />
            </a>

            {/* portal */}
            {/*<a
            href="https://bit.ly/jclvsh-portal"
            target="_blank"
            rel="noreferrer"
            className={
              category["all"] || category["nfts"] || category["ethereum"]
                ? "card card-link"
                : "hidden"
            }
            onClick={() => handleCardClick("portal")}
          >
            <h1 className="heading">portal</h1>
            <p className="paragraph">
              exclusive access to your fav artists and influencers
            </p>
            <p className="paragraph">tech stack: react, hardhat, metamask, solidity, ethereum</p>
            <p className="paragraph">
              features: wallet authentication, smart contracts, NFTs, ethereum
              blockchain, web3
            </p>
            <img src="images/portal-homepage.png" alt="portal homepage" />
          </a>*/}

            {/* fanime */}
            <a
              href="https://bit.ly/jclvsh-fanime"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["gaming"] ||
                  category["nfts"] ||
                  category["ethereum"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("fanime")}
            >
              <h1 className="heading">fanime</h1>
              <p className="paragraph">battle of the animes</p>
              <p className="paragraph">
                tech stack: react, hardhat, metamask, solidity, ethereum
              </p>
              <p className="paragraph">
                features: wallet authentication, smart contracts, NFTs, gaming,
                ethereum blockchain, web3
              </p>
              <img src="images/fanime-homepage.png" alt="fanime homepage" />
            </a>

            {/* YeDAO */}
            {/*<a
            href="https://bit.ly/jclvsh-yedao"
            target="_blank"
            rel="noreferrer"
            className={
              category["all"] ||
              category["nfts"] ||
              category["daos"] ||
              category["ethereum"]
                ? "card card-link"
                : "hidden"
            }
            onClick={() => handleCardClick("YeDAO")}
          >
            <h1 className="heading">YeDAO</h1>
            <p className="paragraph">the DAO for Kanye West memes</p>
            <p className="paragraph">
              tech stack: react, hardhat, metamask, solidity, ethereum
            </p>
            <p className="paragraph">
              features: wallet authentication, smart contracts, NFTs, governance
              tokens, DAOs, ethereum blockchain, web3
            </p>
            <img src="images/yedao-homepage.png" alt="yedao homepage" />
          </a>*/}

            {/* LNS */}
            <a
              href="https://bit.ly/jclvsh-lns"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["nfts"] || category["polygon"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("LNS")}
            >
              <h1 className="heading">lavish name service</h1>
              <p className="paragraph">mint a lavish name service domain</p>
              <p className="paragraph">
                tech stack: react, hardhat, metamask, solidity, polygon
              </p>
              <p className="paragraph">
                features: wallet authentication, smart contracts, NFTs, polygon
                blockchain, web3
              </p>
              <img src="images/lns-homepage.png" alt="lns homepage" />
            </a>

            {/* gifly */}
            <a
              href="https://bit.ly/jclvsh-gifly"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["solana"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("gifly")}
            >
              <h1 className="heading">gifly</h1>
              <p className="paragraph">dank gifs and memes</p>
              <p className="paragraph">
                tech stack: react, anchor, phantom, rust, solana
              </p>
              <p className="paragraph">
                features: wallet authentication, programs, solana blockchain,
                web3
              </p>
              <img src="images/gifly-homepage.png" alt="gifly homepage" />
            </a>

            {/* xclsv */}
            {/*<a
            href="https://bit.ly/jclvsh-xclsv"
            target="_blank"
            rel="noreferrer"
            className={
              category["all"] || category["nfts"] || category["solana"]
                ? "card card-link"
                : "hidden"
            }
            onClick={() => handleCardClick("xclsv")}
          >
            <h1 className="heading">xclsv</h1>
            <p className="paragraph">
              xclsv drops
            </p>
            <p className="paragraph">tech stack: react, anchor, phantom, rust, metaplex, solana</p>
            <p className="paragraph">
              features: wallet authentication, programs, NFTs, solana blockchain, web3
            </p>
            <img src="images/xclsv-homepage.png" alt="xclsv homepage" />
          </a>*/}

            {/* twooter */}
            <a
              href="https://bit.ly/jclvsh-twooter"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] || category["gaming"] || category["terra"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("twooter")}
            >
              <h1 className="heading">twooter</h1>
              <p className="paragraph">a first person twooter</p>
              <p className="paragraph">
                tech stack: react, terrain, rust, terra
              </p>
              <p className="paragraph">
                features: wallet authentication, smart contracts, gaming, terra
                blockchain, web3
              </p>
              <img src="images/twooter-homepage.png" alt="twooter homepage" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Freelance;
