import React, { useEffect } from "react";
import amplitude from "amplitude-js";

const PAGE = "Mogle";

const Mogle = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page");
  }, []);

  const handleLinkClick = (link) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + link);
  };

  return (
    <>
      <div className="section">
        <div className="div-block">
          <a
            href="https://mogleapp.com/"
            className="w-inline-block"
            target="_blank"
            rel="noreferrer"
          >
            <img
              onClick={() => handleLinkClick("Mogle")}
              src="logos/Mogle-Logo-Black.png"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, 256px"
              srcSet="logos/Mogle-Logo-Black.png 500w, logos/Mogle-Logo-Black.png 800w, logos/Mogle-Logo-Black.png 1080w, logos/Mogle-Logo-Black.png 1600w, logos/Mogle-Logo-Black.png 2000w, logos/Mogle-Logo-Black.png 2600w, logos/Mogle-Logo-Black.png 3582w"
              alt=""
              className="image-2"
            />
          </a>
          <p className="paragraph">product suite for freelancers in web3</p>
          <div className="div-block-2">
            <a
              href="mailto:joshchoi@mogleinsurance.com"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("GMail")}
                src="logos/gmail.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://discord.gg/FsQhcdXkRz"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Discord")}
                src="logos/discord.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://t.me/+CPIoF55FCEo3NDU5"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Telegram")}
                src="logos/telegram.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://medium.com/@joshchoi4881"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Medium")}
                src="logos/medium.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://mogle.substack.com/welcome"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Substack")}
                src="logos/substack.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/mogleapp"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("LinkedIn")}
                src="logos/linkedin.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://www.facebook.com/mogleapp/"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Facebook")}
                src="logos/facebook.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://www.instagram.com/mogleapp/"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Instagram")}
                src="logos/instagram.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://twitter.com/mogleapp"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Twitter")}
                src="logos/twitter.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mogle;
